import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes, logoWidth } from 'components';

const sectionBorder = (theme: ThemeTypes.Theme) =>
	theme.border({ color: theme.palette.body.light, radius: 0, kind: 'bottom' });

const useStyles = makeStyles(
	(theme) => ({
		container: {
			height: theme.headerHeight.sm,
			paddingLeft: 0,
			paddingRight: 0,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			'& > .MuiTextField-root': {
				width: '100%'
			}
		},
		// will be applied to drawer root
		root: {
			height: 0
		},
		registrationLoginContainer: {
			display: 'flex',
			paddingLeft: theme.spacing(2.5),
			flexDirection: 'column',
			marginTop: theme.spacing(2),
			marginRight: 'auto',
			...theme.border({ color: theme.palette.body.light, radius: 0, kind: 'top' })
		},
		primaryButton: {
			display: 'flex',
			justifyContent: 'center',
			margin: theme.spacing(3, 0, 2, 0)
		},
		headerLink: {
			display: 'flex',
			width: '100%',
			height: '100%',
			alignItems: 'center'
		},
		logo: {
			width: logoWidth,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			marginRight: theme.spacing(1),

			'& img': {
				maxWidth: '100%'
			}
		},
		toolbar: {
			color: theme.palette.body['700'],
			height: 'unset',
			padding: theme.spacing(3, 2.5, 3, 2.5)
		},
		sectionsMenuContainer: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			padding: theme.spacing(0, 2, 2, 2),
			flexDirection: 'column',
			alignItems: 'center',
			...sectionBorder(theme)
		},
		list: {
			marginBottom: 0,
			...sectionBorder(theme)
		},
		listItem: {
			marginBottom: 0
		},
		iconsContainer: {
			display: 'flex',
			alignItems: 'center'
		},
		icon: {
			width: 24,
			height: 24,
			cursor: 'pointer',
			marginLeft: theme.spacing(2)
		},
		backIcon: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1),
			'& svg': {
				color: theme.palette.secondary.main
			}
		},
		avatar: {
			border: 0,
			minWidth: 64,
			minHeight: 64,
			width: 64,
			height: 64,
			background: 'linear-gradient(180deg, #d8f1f673 0%, #D8F1F6 100%)',
			borderRadius: '50%',
			display: 'flex',
			color: theme.palette.secondary.main,
			margin: theme.spacing(4, 'auto', 1.5),
			alignItems: 'center',
			...theme.typography.textMedium,
			'& > svg': {
				width: 'unset',
				margin: 'auto',
				fill: 'currentColor'
			}
		},
		// sub menu styles
		subMenuHeaderContainer: {
			display: 'flex',
			alignItems: 'center',
			cursor: 'pointer'
		},
		userDetails: { display: 'flex', flexDirection: 'column' },
		userFullName: {
			fontSize: 16,
			fontWeight: 700,
			lineHeight: '24px',
			color: '#1B3F62',
			margin: theme.spacing(0, 4, 4, 4),
			textAlign: 'center'
		},
		userDivider: { width: 'unset', margin: theme.spacing(0, 2) },
		companyLogo: {
			width: 24,
			height: 24,
			marginRight: theme.spacing(1.5),
			flexShrink: 0
		},
		addonIcon: {
			border: 0
		}
	}),
	// to have more priority over DrawerMenu
	{ name: 'Header.mobile', index: 10 }
);

export default useStyles;

export const useToolbarHeaderStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
	avatar: {
		border: 0,
		minWidth: 56,
		minHeight: 56,
		width: 56,
		height: 56,
		background: 'linear-gradient(180deg, #d8f1f673 0%, #D8F1F6 100%)',
		borderRadius: '50%',
		display: 'flex',
		color: theme.palette.secondary.main,
		marginRight: theme.spacing(2),
		alignItems: 'center',
		...theme.typography.textMedium,
		'& > svg': {
			width: 'unset',
			margin: 'auto',
			fill: 'currentColor'
		}
	},
	container: {
		display: 'flex',
		cursor: 'pointer'
	},
	rightContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: theme.spacing(0.5)
	},
	subtitle: { lineHeight: '24px', color: '#00000088' },
	title: {
		fontWeight: 700,
		color: theme.palette.secondary.main
	},
	signIn: {
		display: 'flex',
		alignItems: 'center'
	},
	signInIcon: {
		width: 16,
		height: 16,
		marginLeft: theme.spacing(0.5),
		color: theme.palette.secondary.main
	},
	caretRight: {
		marginLeft: theme.spacing(1),
		marginTop: 'auto',
		marginBottom: 'auto',
		color: theme.palette.secondary.main
	}
}));
