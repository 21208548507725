import type { UrlObject } from 'url';
const UTM_KEYS = {
	utm_campaign: 'utm_campaign',
	utm_medium: 'utm_medium',
	utm_source: 'utm_source',
	utm_content: 'utm_content',
	utm_term: 'utm_term'
} as const;
type UTM_KEYS = typeof UTM_KEYS[keyof typeof UTM_KEYS];

type UTM_PARAMETERS = Partial<Record<UTM_KEYS, string>>;

const getUTMParameters = (searchParams: string): UTM_PARAMETERS => {
	searchParams = searchParams || (typeof window !== 'undefined' && window.location.search) || '';

	const utmParameters: UTM_PARAMETERS = {};

	if (searchParams.includes('utm_')) {
		const urlSearchParams = new URLSearchParams(searchParams);
		Object.keys(UTM_KEYS).forEach((key) => {
			const value = urlSearchParams.get(key);
			if (value) {
				utmParameters[key as UTM_KEYS] = value;
			}
		});
	}

	return utmParameters;
};

export const enrichWithUTMParameters = (
	urlArg: UrlObject | string,
	searchParams: string
): string => {
	const url = typeof urlArg !== 'string' ? urlArg.href || '' : urlArg;

	if (url.includes('utm_')) {
		return url;
	}

	if (url.startsWith('mailto:')) {
		return url;
	}

	// don't pass parameters to external links
	const whitelistDomains = ['fomf.de', 'fomf.ch', 'fomf.at', 'cmed.org.uk'];
	if (url.startsWith('http') && whitelistDomains.every((domain) => !url.includes(domain))) {
		return url;
	}

	const oldUrlSplit = url.split('?');

	const urlQuery = new URLSearchParams(oldUrlSplit[1]);
	const utmQuery = new URLSearchParams(getUTMParameters(searchParams));

	const urlQueryString = urlQuery.toString();
	const utmQueryString = utmQuery.toString();

	const newQueryString =
		urlQueryString.length > 0
			? `?${urlQueryString}&${utmQueryString}`
			: utmQueryString.length > 0
			? `?${utmQueryString}`
			: '';

	const newUrl = oldUrlSplit[0] + newQueryString;

	return newUrl;
};
