import React, { ReactNode, FC } from 'react';
import { Button as MUIButton } from '@mui/material';
import classnames from 'classnames';

import useStyles from './Button.styles';
import { ButtonProps, ButtonIcon } from './Button.types';
import { Icon, isIconName, Typography } from '../';

const renderIcon = (
	icon: ButtonIcon,
	iconStrokeWidth: number | undefined,
	className: string
): ReactNode => {
	if (!icon) {
		return null;
	}

	return typeof icon === 'string' && isIconName(icon) ? (
		<Icon className={className} icon={icon} strokeWidth={iconStrokeWidth} />
	) : (
		icon
	);
};

type CustomButton = FC<ButtonProps> & {
	useEnrichWithUTMParameters?: () => (url: string) => string;
};

const defaultUseEnrichWithUTMParameters = () => (url: string) => url;

const Button: CustomButton = (props: ButtonProps) => {
	const {
		children,
		className = '',
		disabled = false,
		endIcon,
		color = 'primary',
		localeId,
		localeValues,
		startIcon,
		onClick,
		variant = 'contained',
		iconStrokeWidth,
		onlyIcon: onlyIconProp,
		classes: propsClasses,
		disableRipple = true,
		typographyProps,
		size = 'medium',
		uppercase = true,
		minimal = false,
		href: hrefProp,
		unstyled,
		...others
	} = props;

	const classes = useStyles(props);

	const enrichWithUTMParameters = (
		Button.useEnrichWithUTMParameters || defaultUseEnrichWithUTMParameters
	)();

	const href = hrefProp ? enrichWithUTMParameters(hrefProp) : hrefProp;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		!disabled && onClick && onClick(event);
	};

	const onlyIcon = onlyIconProp || (!children && !localeId);

	if (unstyled) {
		return (
			<MUIButton
				className={className}
				disabled={disabled}
				onClick={handleClick}
				startIcon={renderIcon(startIcon, iconStrokeWidth, classes.icon)}
				endIcon={renderIcon(endIcon, iconStrokeWidth, classes.icon)}
				variant={variant}
				disableRipple={disableRipple}
				color={color}
				href={href}
				{...others}
			>
				{children}
			</MUIButton>
		);
	}

	return (
		<MUIButton
			className={classnames(
				classes.button,
				classes[variant],
				variant === 'contained' ? classes[color] : undefined,
				{
					[classes.disabled]: disabled,
					[classes.uppercase]: uppercase,
					[classes.minimal]: minimal
				},
				classes[size],
				className
			)}
			classes={{
				startIcon: classnames(classes.startIcon, { [classes.onlyIcon]: onlyIcon }),
				endIcon: classnames(classes.endIcon, { [classes.onlyIcon]: onlyIcon }),
				root: classes.root
			}}
			disabled={disabled}
			onClick={handleClick}
			startIcon={renderIcon(startIcon, iconStrokeWidth, classes.icon)}
			endIcon={renderIcon(endIcon, iconStrokeWidth, classes.icon)}
			variant={variant}
			disableRipple={disableRipple}
			color={variant === 'contained' ? color : undefined}
			href={href}
			{...others}
		>
			{!onlyIcon && (
				<Typography
					localeId={localeId}
					title={children}
					localeValues={localeValues}
					variant="linkSmall"
					{...typographyProps}
				/>
			)}
		</MUIButton>
	);
};

Button.useEnrichWithUTMParameters = defaultUseEnrichWithUTMParameters;

export type { ButtonProps };
export default Button;
