import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { Link } from 'react-scroll';
import Sticky from 'react-stickynode';

import useStyles from './AnchorHeader.styles';
import { Typography } from '../Typography';
import { AnchorHeaderTab, AnchorHeaderProps } from './AnchorHeader.types';
const defaultHeight = 56;

const AnchorHeader = (props: AnchorHeaderProps) => {
	const {
		top = 0,
		height = defaultHeight,
		hashSpy = false,
		tabs,
		innerZ = 1000,
		selectedTab,
		onSelectedTab,
		...others
	} = props;

	const classes = useStyles({ height });
	const getLinkOffset = () => {
		// react-scroll doumentation: Scroll additional px ( like padding ) by clicking on each link,
		// it will scroll down to that item. But in this page there are two fixed elements on top of
		// the page, so the item will be positioned under them first is the main header of the application,
		// second is the header of this page which is also fixed so we need to set an offset equals to
		// the sum of this two heights
		return -1 * top - 1 * height;
	};
	const renderTab = (tab: AnchorHeaderTab) => {
		const { tabName, localeId } = tab;
		return (
			<Tab
				key={localeId}
				className={classes.tab}
				label={
					<Link
						activeClass="active"
						className={classes.link}
						to={tabName}
						hashSpy={hashSpy}
						spy
						smooth
						duration={500}
						onClick={() => onSelectedTab && onSelectedTab(tabName)}
						offset={getLinkOffset()}
					>
						<Typography variant="textXSmall" localeId={localeId} />
					</Link>
				}
				value={tabName}
			/>
		);
	};

	return (
		<Sticky top={top} innerZ={innerZ} {...others}>
			<Tabs
				value={selectedTab}
				className={classes.links}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
			>
				{tabs.map(renderTab)}
			</Tabs>
		</Sticky>
	);
};

export default AnchorHeader;
