import getDomainFromLocation from './getDomainFromLocation';
import { PACKAGE_TO_BASE_PATH } from '../constants';

const isDomainChFr = () => {
	if (typeof window === 'undefined') {
		return false;
	}

	const domain = getDomainFromLocation();

	if (domain === 'ch') {
		const pathname = window.location.pathname;
		const pathParts = pathname.split('/');
		if (
			(Object.values(PACKAGE_TO_BASE_PATH).includes(pathParts[1] as PACKAGE_TO_BASE_PATH) &&
				pathParts[2] === 'fr-CH') ||
			pathParts[1] === 'fr-CH'
		) {
			return true;
		}
	}

	return false;
};

export default isDomainChFr;
