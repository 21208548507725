import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes, logoWidth } from 'components';

const logoMarginRight = (theme: ThemeTypes.Theme) => theme.spacing(3);

const useStyles = makeStyles(
	(theme) => ({
		container: {
			padding: 0
		},
		searchBoxRoot: {
			flex: 1
		},
		topContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 80
		},
		primaryButton: {
			marginRight: theme.spacing(3),
			backgroundColor: theme.palette.secondary[300],
			borderRadius: 6
		},
		link: {
			display: 'flex',
			width: '100%',
			height: '100%',
			alignItems: 'center',

			'& img': {
				maxWidth: '100%'
			}
		},
		logoWrapperContainer: {
			display: 'flex',
			alignItems: 'center',
			flex: 1,
			marginInlineEnd: 14
		},
		logo: {
			width: logoWidth,
			height: '100%',
			display: 'flex',
			marginRight: logoMarginRight(theme)
		},
		navbarContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		avatar: {
			width: 40,
			height: 40,
			color: theme.palette.common.white,
			backgroundColor: theme.palette.secondary[700],
			...theme.typography.body2
		},
		userInfoContainer: {
			display: 'flex',
			alignItems: 'center',
			marginLeft: theme.spacing(1)
		},
		iconButton: {
			width: 44,
			height: 44,
			display: 'flex',
			marginRight: theme.spacing(1)
		},
		icon: {
			width: 'unset',
			height: 'unset',
			'& svg': {
				color: theme.palette.primary.main
			}
		},
		sectionsMenuContainer: {
			marginRight: theme.spacing(2)
		},
		topicsLink: {
			display: 'flex',
			paddingInline: 16,
			paddingBlock: 14,
			alignItems: 'center',
			fontFamily: 'Roboto',
			letterSpacing: 0.25,
			fontWeight: 700,
			fontSize: 14,
			borderRadius: 24,
			transition: 'background-color 0.1s',

			'&:hover': {
				backgroundColor: '#0000000A'
			}
		},
		topicsIcon: {
			width: 20,
			height: 20,
			marginRight: theme.spacing(1)
		},
		searchBox: {
			height: 48,
			width: 452,
			...theme.border({ radius: 24, width: 0 }),
			backgroundColor: '#F4F7F9',
			...theme.typography.textXSmall,
			color: theme.palette.primary.main,
			transition: 'background-color 0.1s',
			paddingInline: 16,
			'&:hover': {
				backgroundColor: '#2A4C6C14'
			},
			'&.Mui-focused': {
				backgroundColor: 'white',
				boxShadow: '0px 1px 6px 0px #0000001F'
			},
			'&.Mui-focused $searchBoxNotchedOutline': {
				...theme.border({ radius: 0, width: 0 })
			},
			'&& ::placeholder': {
				color: theme.palette.primary[500],
				fontWeight: 400,
				opacity: 1,
				...theme.typography.textXSmall
			}
		},
		searchBoxNotchedOutline: {
			borderWidth: 0
		}
	}),
	// to have more priority over Button primary color
	{ name: 'Header.desktop', index: 10 }
);

export default useStyles;
