import {
	COUNTRY_CODES_3,
	COUNTRY_CODES,
	LOCALE_TO_COUNTRY_CODE,
	LOCALE_TO_COUNTRY_CODE_3,
	COUNTRY_CODE_3_TO_LOCALE
} from '../constants';

export const isCountryCode3 = (country: string): country is CountryCode3 =>
	COUNTRY_CODES_3.includes(country as CountryCode3);

export const ensureCountryCode3 = (country: string): CountryCode3 => {
	if (!isCountryCode3(country)) {
		throw new Error(`"${country}" is not a CountryCode3 format`);
	}
	return country;
};

export const isCountryCode = (country: string): country is CountryCode =>
	COUNTRY_CODES.includes(country as CountryCode);

export const ensureCountryCode = (country: string): CountryCode => {
	if (!isCountryCode(country)) {
		throw new Error(`"${country}" is not a CountryCode2 format`);
	}
	return country;
};

export const getCountryCode = (locale: Locale): CountryCode => {
	return LOCALE_TO_COUNTRY_CODE[locale];
};

export const getCountryCode3 = (locale: Locale): CountryCode3 => {
	return LOCALE_TO_COUNTRY_CODE_3[locale];
};

export const getLocaleFromCountryCode3 = (countryCode: CountryCode3): Locale => {
	return COUNTRY_CODE_3_TO_LOCALE[countryCode];
};
