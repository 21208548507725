import React from 'react';
import { Box } from '@mui/material';
import useStyles from './PayNotPaidMembershipHeaderBanner.styles';
import { Button, Icon, Typography } from 'components';
import { BOOKING_URLS, useRouter } from '../../..';

export const PayNotPaidMembershipHeaderBanner = ({
	membershipId,
	isMobile
}: {
	membershipId: string;
	isMobile: boolean;
}) => {
	const classes = useStyles();
	const router = useRouter();

	if (router.pathname === '/dashboard/membership') {
		return null;
	}

	return (
		<Box className={classes.container}>
			<Box className={classes.contentContainer}>
				<Icon icon="info" className={classes.infoIcon} />
				{isMobile ? (
					<Typography
						className={classes.title}
						variant="body2"
						localeId="common.header.pay-not-paid.mobile"
					/>
				) : (
					<Typography
						className={classes.title}
						variant="subtitle2"
						localeId="common.header.pay-not-paid.desktop"
					/>
				)}
				<Button
					className={classes.button}
					color="error"
					variant="outlined"
					size={isMobile ? 'small' : 'medium'}
					href={`${BOOKING_URLS.memberships}/${membershipId}`}
				>
					<Typography
						className={classes.buttonText}
						localeId="common.membership.pay-not-paid.button"
					/>
				</Button>
			</Box>
		</Box>
	);
};
