import React from 'react';
import NextImage, { ImageProps } from 'next/image';
import { useIntl } from 'react-intl';

import { Types, useIsDesktop, formatTranslation } from '../..';

type Props = Omit<ImageProps, 'alt'> & {
	/**
	 * Accepts a string or a JSON containing a valid translation
	 */
	alt: Types.Scalars['JSON'] | string;

	/**
	 * To specify the width in desktop device screen sizes
	 */
	mdWidth?: number;

	/**
	 * To specify the height in desktop device screen sizes
	 */
	mdHeight?: number;
};

/**
 * This component is a wrapper of the Next/image that just makes the "alt" property required
 * to fulfill the SEO requirements.
 */
const Image = (props: Props) => {
	const {
		alt: altProp,
		width: widthProp,
		height: heightProp,
		mdWidth,
		mdHeight,
		...others
	} = props;

	const { locale } = useIntl();
	const isDesktop = useIsDesktop();
	const width = isDesktop && mdWidth ? mdWidth : widthProp;
	const height = isDesktop && mdHeight ? mdHeight : heightProp;
	const alt = typeof altProp === 'string' ? altProp : formatTranslation(altProp, { locale });

	return <NextImage alt={alt} width={width} height={height} {...others} />;
};

export default Image;
