import React, { RefObject, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';
import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { stringify } from 'query-string';

import { ThemeTypes } from 'components';

import { PACKAGE_NAME, PACKAGE_TO_BASE_PATH } from '../../constants';
import { useRouter } from '../../hooks';
import { enrichWithUTMParameters } from '../../utils/utmParameters';

const useStyles = makeStyles<ThemeTypes.Theme>(
	(theme) => ({
		link: {
			color: theme.palette.primary.main,
			fontWeight: 500,
			textDecoration: 'none'
		}
	}),
	{ name: 'Link' }
);

export type NEXT_LINK_PACKAGE = PACKAGE_NAME | 'absolute';

const PACKAGE_NAME_TO_BASE_PATH: Record<PACKAGE_NAME, string> = {
	catalog: ''
};
const attachLeadingSlashIfNeeded = (href: string | undefined): string => {
	if (!href) {
		return '';
	}
	if (href.startsWith('http')) {
		return href;
	}
	return href.startsWith('/') ? href : `/${href}`;
};

const attachPackageBasePathIfNeeded = (basePath: string, targetPackage: PACKAGE_NAME): string => {
	// If we are in the same package, do not attach as nextjs already does that
	if (PACKAGE_NAME_TO_BASE_PATH[targetPackage] === basePath) {
		return '';
	}
	return PACKAGE_TO_BASE_PATH[targetPackage];
};

type Props = Pick<MUILinkProps, 'children' | 'className' | 'target'> & {
	shallow?: boolean;
	href?: string;
	packageTarget: PACKAGE_NAME | 'absolute';
	onClick?: (
		e:
			| React.MouseEvent<HTMLAnchorElement, MouseEvent>
			| React.MouseEvent<HTMLSpanElement, MouseEvent>
	) => void;
	['data-testid']?: string;
};

type LinkResult = {
	linkWithLocale: string | undefined;
	linkLocale: Locale | false | undefined;
};
const NextLink = React.forwardRef((props: Props, ref: React.Ref<HTMLElement>) => {
	const { children, packageTarget, href, className, onClick, target, shallow, ...otherProps } =
		props;
	const { basePath, query } = useRouter();
	const { locale } = useIntl();
	const classes = useStyles();

	const { linkWithLocale, linkLocale }: LinkResult = useMemo(() => {
		if (!href) {
			return { linkWithLocale: undefined, linkLocale: undefined };
		}
		if (packageTarget === 'absolute') {
			return { linkWithLocale: attachLeadingSlashIfNeeded(href), linkLocale: undefined };
		}
		const language = locale === 'fr-CH' ? (href.startsWith('/') ? '/fr-CH' : '/fr-CH/') : '';
		const linkLocale = locale === 'fr-CH' ? 'fr-CH' : false;
		if (packageTarget === 'catalog') {
			return { linkWithLocale: attachLeadingSlashIfNeeded(`${language}${href}`), linkLocale };
		}
		const url = `${attachLeadingSlashIfNeeded(
			`${attachPackageBasePathIfNeeded(basePath, packageTarget)}${language}${href}`
		)}`;
		return { linkWithLocale: url, linkLocale };
	}, [locale, href, basePath, packageTarget]);

	const linkClasses = classNames(classes.link, className);
	const shouldRenderRegularLink =
		packageTarget === 'absolute' || PACKAGE_NAME_TO_BASE_PATH[packageTarget] !== basePath;

	// If we have an absolute link or navigating between nextjs apps, we need a normal link as client side navigation won't work
	if (shouldRenderRegularLink || !href || target === '_blank') {
		const finalHref = enrichWithUTMParameters(linkWithLocale || '', stringify(query));
		return (
			<MUILink
				className={linkClasses}
				ref={ref as RefObject<HTMLAnchorElement>}
				component="a"
				href={finalHref}
				onClick={onClick}
				target={target}
				{...otherProps}
			>
				{children}
			</MUILink>
		);
	}

	const finalHref = enrichWithUTMParameters(attachLeadingSlashIfNeeded(href), stringify(query));

	// Next link that enables client side navigation
	return (
		<Link href={finalHref} locale={linkLocale} as={finalHref} passHref shallow={shallow}>
			<MUILink
				className={linkClasses}
				ref={ref as RefObject<HTMLAnchorElement>}
				component="a"
				onClick={onClick}
				{...otherProps}
			>
				{children}
			</MUILink>
		</Link>
	);
});

NextLink.displayName = 'NextLink';

export default NextLink;
