import { Dispatch, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';
import _once from 'lodash/once';

import useChangeURL, { ChangeURL } from './useChangeURL';
import getStateByNonce from '../cookies/getStateByNonce';

type FallbackURLArgs = {
	url: string;
	destinationPackage?: PACKAGE_NAME;
	createAbsolute?: boolean;
};

const redirect = _once(
	(
		nonce: string | Array<string> | null | undefined,
		changeURL: ChangeURL,
		fallbackURLArgs?: FallbackURLArgs
	) => {
		const redirectState =
			nonce && !Array.isArray(nonce) && nonce.length > 0 ? getStateByNonce(nonce) : null;
		if (redirectState?.redirectTo) {
			changeURL(redirectState?.redirectTo);
		} else if (fallbackURLArgs) {
			const { url, destinationPackage, createAbsolute } = fallbackURLArgs;
			changeURL(url, destinationPackage, createAbsolute);
		}
	}
);

/**
 * Reads the "state" query parameter from the URL, checks if there is some information associated
 * to the corresponding nonce and performs the redirection if the state has some "redirectTo" field
 * or if the fallbackURLArgs argument is provided.
 */
const useUrlState = (props: {
	setIsLoading?: Dispatch<SetStateAction<boolean>>;
	fallbackURLArgs?: FallbackURLArgs;
}) => {
	const { setIsLoading, fallbackURLArgs } = props;
	const router = useRouter();
	const changeURL = useChangeURL();
	useEffect(() => {
		if (router.isReady) {
			const nonce = router.query['state'];
			redirect(nonce, changeURL, fallbackURLArgs);
			setIsLoading && setIsLoading(false);
		}
	}, [router.isReady]);
};

export default useUrlState;
