import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ThemeTypes, Typography } from 'components';
import { useRouter } from 'next/router';
import NextLink, { NEXT_LINK_PACKAGE } from '../NextLink';

/**
 * This function is used to replace the query params in the url with the actual values. Example: `/product/[code]` -> `/product/AM`.
 */
const replaceNextJSPageRoutingQueryParamsWithActualValues = (
	url: string,
	query: NodeJS.Dict<string | Array<string>>
) => {
	let newUrl = url;
	if (Object.keys(query).length > 0) {
		for (const pair of Object.entries(query)) {
			let value = pair[1];
			const key = pair[0];
			if (value) {
				if (Array.isArray(value)) {
					if (value.length > 1) {
						throw new Error('Array query params are not implemented.');
					}
					value = value[0] as string;
				}

				newUrl = newUrl.replace(`[${key}]`, value);
			}
		}
	}
	return newUrl;
};

/**
 * This function is used to normalise the url to be relative to the current domain. Additionally, it removes the query string, hash, coalesces double slashes into a single one and removes a trailing slash.
 */
const normaliseUrl = (url: string) => {
	let newUrl = url;

	if (typeof window !== 'undefined' && window.location.origin) {
		newUrl = url.replace(window.location.origin, '');
	}

	newUrl = newUrl.replace(/\/$/, '');
	newUrl = newUrl.replace(/\?.*$/, '');
	newUrl = newUrl.replace(/#.*$/, '');
	newUrl = newUrl.replace(/(?:\/)?fr-CH/, '');
	if (newUrl === '') {
		newUrl = '/';
	}
	if (newUrl[0] !== '/') {
		newUrl = `/${newUrl}`;
	}
	newUrl = newUrl.replace(/([^:/]|^)\/\/+/g, '$1/');
	return newUrl;
};

const useStyles = makeStyles<ThemeTypes.Theme, { isActive: boolean }>((theme) => ({
	linkContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing(1),
		whiteSpace: 'nowrap',
		backgroundColor: ({ isActive }) => (isActive ? '#019ABC1A' : 'initial'),
		paddingBlock: theme.spacing(1),
		paddingInline: theme.spacing(1),
		borderRadius: 10,
		transition: 'background-color 0.1s',

		[theme.breakpoints.down('lg')]: {
			paddingInline: ({ isActive }) => (isActive ? theme.spacing(1) : 6)
		},

		'&:hover': {
			backgroundColor: '#0000000A'
		}
	},
	linkText: {
		color: ({ isActive }) => (isActive ? theme.palette.primary.main : '#59748D'),
		fontWeight: ({ isActive }) => (isActive ? 600 : 400)
	}
}));

type HeaderLinkProps = {
	href: string;
	localeId: string;
	packageName: NEXT_LINK_PACKAGE;
	icon: string | undefined;
};

export const HeaderLink = (props: HeaderLinkProps) => {
	const { href, localeId, icon, packageName } = props;
	const { pathname, query } = useRouter();

	const relativeNormalisedUrl = normaliseUrl(href);
	const normalisedNextJSPageUrl = replaceNextJSPageRoutingQueryParamsWithActualValues(
		pathname,
		query
	);

	const isActive =
		relativeNormalisedUrl === normalisedNextJSPageUrl ||
		(relativeNormalisedUrl === '/partner-fortbildungsforum/videos' &&
			normalisedNextJSPageUrl === '/partner-fortbildungsforum/partners');

	const classes = useStyles({ isActive });
	return (
		<NextLink packageTarget={packageName} className={classes.linkContainer} href={href}>
			<>
				{icon && <img loading="eager" src={icon} alt="" />}
				<Typography variant="textXSmall" className={classes.linkText} localeId={localeId} />
			</>
		</NextLink>
	);
};

export const Testing_HeaderLinkHelpers = {
	normaliseUrl,
	replaceNextJSPageRoutingQueryParamsWithActualValues
};
