import { PACKAGE_LOCAL_PORT, PACKAGE_NAME, PACKAGE_TO_BASE_PATH } from '../constants';
import isDomainChFr from './isDomainChFr';

const removeDoubleSlashes = (url: string): string => {
	return url.replace(/([^:/]|^)\/\/+/g, '$1/');
};

/**
 * TODO: arsinclair: remove this function when packages are merged.
 * @deprecated
 */
const createURL = (
	url: string,
	destinationPackage?: PACKAGE_NAME | undefined,
	createAbsolute?: boolean
): { url: string; type: 'absolute' } => {
	if (url.startsWith('http://') || url.startsWith('https://')) {
		return { url, type: 'absolute' };
	}

	if (!destinationPackage) {
		const urlDestinationPackageTemp = url.split('/')[1] as PACKAGE_NAME;
		const urlDestinationPackage = Object.values(PACKAGE_NAME).includes(
			urlDestinationPackageTemp
		)
			? urlDestinationPackageTemp
			: undefined;

		if (urlDestinationPackage) {
			const urlArray = url.split('/');
			urlArray.splice(1, 1);
			url = urlArray.join('/');
			destinationPackage = urlDestinationPackage;
		} else {
			throw new Error(
				'Unable to calculate destination package. Check the link or specify destination package explicitly'
			);
		}
	}
	const language = isDomainChFr() ? (url.startsWith('/') ? '/fr-CH' : '/fr-CH/') : '';

	const isWindow = typeof window !== 'undefined';

	const origin = isWindow ? window.location.origin : '';

	const localPort = isWindow && window.location.port;
	const port = PACKAGE_LOCAL_PORT[destinationPackage];
	const destinationPackageURL = PACKAGE_TO_BASE_PATH[destinationPackage];
	const localOrigin = isWindow && window.location.origin.replace(`:${localPort}`, `:${port}`);
	const pathUrl =
		url.startsWith('/') && destinationPackage !== 'catalog' && destinationPackageURL === ''
			? url.replace('/', '')
			: url;
	const localURL = !!localPort && `${localOrigin}/${destinationPackageURL}${language}${pathUrl}`;

	const absoluteURL = localURL || `${origin}/${destinationPackageURL}${language}${pathUrl}`;

	if (createAbsolute) {
		return { url: removeDoubleSlashes(absoluteURL), type: 'absolute' };
	}

	if (localURL) {
		return { url: removeDoubleSlashes(localURL), type: 'absolute' };
	}

	return { url: removeDoubleSlashes(absoluteURL), type: 'absolute' };
};

export default createURL;
