const getCookie = (name: string) => {
	if (typeof window !== 'undefined') {
		const decodedCookie = decodeURIComponent(document.cookie).split(';');
		for (let i = 0; i < decodedCookie.length; i++) {
			while (decodedCookie[i]?.charAt(0) === ' ') {
				decodedCookie[i] = decodedCookie[i]?.substring(1) || '';
			}
			if (decodedCookie[i]?.indexOf(`${name}=`) == 0) {
				return decodedCookie[i]?.substring(`${name}=`.length, decodedCookie[i]?.length);
			}
		}
	}
	return '';
};

export default getCookie;
