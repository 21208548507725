import React, { useEffect, useState } from 'react';
import { HeaderDesktop } from './Header.desktop/Header.desktop';
import { HeaderMobile } from './Header.mobile/Header.mobile';
import type { HeaderProps } from './Header.types';
import { useIsDesktop } from '../../hooks';

export type HeaderQueryParams = {
	search?: string | null;
};

export const Header = (props: HeaderProps) => {
	const { isMobile } = props;

	const [isClientSide, setIsClientSide] = useState(false);
	const isDesktop = useIsDesktop({ defaultMatches: isMobile === false });

	useEffect(() => {
		setIsClientSide(true);
	}, []);

	// Disable ssr
	if (!isClientSide && isMobile === null) {
		return null;
	} else if (!isDesktop) {
		return <HeaderMobile {...props} />;
	}
	return <HeaderDesktop {...props} />;
};
